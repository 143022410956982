import { PageContextProps } from 'application/contexts/PageContext';
import Application from 'application/entities/Application';
import { getChatbotConfiguration } from 'application/repositories/chatbotRepository';
import { getDictionaryItems } from 'application/repositories/dictionaryRepository';
import { getPageContent } from 'application/repositories/pageContentRepository';
import { getSite } from 'application/repositories/siteRepository';
import { getWebinarItems } from 'application/repositories/webinarRepository';
import { LayoutFeature } from 'features/Layouts/Layout/LayoutFeature';
import { PageTypeFeature } from 'features/Modules/PageType/PageTypeFeature';
import { GetStaticPaths, GetStaticProps } from 'next';
import getConfig from 'next/config';
import { DXPContentPage } from 'pages/_app';
import { useEffect } from 'react';
import { useCentralStore } from 'store';
import logger from 'utilities/logger';

const Page: DXPContentPage<PageProps> = (pageProps) => {
	const { page, webinarItems } = pageProps ?? {};
	const { setIsMobile } = useCentralStore();

	useEffect(() => {
		const mobileRegex = /Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
		const userAgentIsMobile = mobileRegex.test(navigator.userAgent);
		setIsMobile(userAgentIsMobile);
	}, [setIsMobile]);

	return (
		<LayoutFeature {...pageProps}>
			<PageTypeFeature {...page} webinarItems={webinarItems} />
		</LayoutFeature>
	);
};

export const getStaticPaths: GetStaticPaths = async () => {
	return {
		paths: [],
		// In the current setup fallback MUST be blocking
		// If fallback is set to true next will try to return the
		// page with an initially empty data-set, so that you can show
		// a loading page or do other operations until data is available
		// https://nextjs.org/docs/api-reference/data-fetching/get-static-paths#fallback-blocking
		fallback: 'blocking',
	};
};

type PageProps = {
	content: Content.PageContent;
	dictionary: Content.DictionaryItem[] | Models.Dictionary;
	site: Models.Site;
	host: string | string[];
};

export const getStaticProps: GetStaticProps<PageProps> = async (context) => {
	const { params, locale, preview, previewData: untypedPreviewData } = context;
	const { publicRuntimeConfig } = getConfig() ?? {};

	const { DOMAIN_PROTOCOL: protocol, CACHE_REVALIDATE: cacheRevalidate } = publicRuntimeConfig ?? {};
	const { page, host } = params ?? {};

	const path = page?.[page?.length - 1];
	if (path?.endsWith('.xml')) {
		return {
			notFound: true,
			revalidate: 1,
		};
	}
	const requestPath = page?.length > 0 ? (page as string[])?.join('/') : '/';
	const hostName = atob(host as string);
	locale ? Application.setLocale(locale) : null;
	if (hostName.includes('localhost:8080')) {
		return {
			redirect: {
				destination: '/api/isAlive',
				permanent: false,
			},
		};
	}

	const websiteUrl = `${protocol ? `${protocol}://` : ''}${hostName}`;

	const requestUrlObject = new URL(`${websiteUrl}${requestPath.startsWith('/') ? requestPath : '/' + requestPath}`);

	const previewData = untypedPreviewData as Models.PreviewData;
	const pageContext = { preview, previewData, host: hostName, url: requestUrlObject.href } as Partial<PageContextProps>;
	try {
		const site = await getSite({ pageContext });
		const dictionary = await getDictionaryItems({ pageContext, fromCDA: site?.settings?.experimental?.active });
		const content = await getPageContent({
			url: requestUrlObject.pathname + requestUrlObject.search,
			pageContext,
		});

		const chatbotConfigId = content?.page?.properties?.chatbotTenant?.properties?.settingsId?.properties?.chatbotId;
		const chatbotConfig = site?.settings?.chatbot?.active && chatbotConfigId ? await getChatbotConfiguration(chatbotConfigId) : null;

		const { documentType } = content?.page ?? {};

		let webinarItems = null;
		if (documentType === 'webinarPage') {
			webinarItems = await getWebinarItems(site.theme);
			webinarItems = webinarItems !== undefined ? webinarItems : null;
		}

		const { redirectLink } = content?.page?.properties ?? {};
		if (redirectLink) return { redirect: { permanent: true, destination: redirectLink?.url } };

		return {
			props: {
				content: { ...content, webinarItems, chatbotConfig },
				dictionary,
				site: site,
				host: hostName,
			},
			revalidate: cacheRevalidate ? Number(cacheRevalidate) : 60,
		};
	} catch (error) {
		const { statusCode } = error;
		if (statusCode === 301 || statusCode === 302 || statusCode === 307 || statusCode === 308) {
			return {
				redirect: {
					destination: error.url,
					permanent: statusCode === 301 || statusCode === 308,
				},
			};
		}
		if (statusCode === 404) {
			return {
				notFound: true,
				revalidate: 1,
			};
		}

		logger.error(error);
		throw new Error(error);
	}
};

export default Page;
